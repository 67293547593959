import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index"
import myAxios from './util/axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '../src/assets/style/style.scss'

const app = createApp(App)
app.config.globalProperties.$host = process.env.VUE_APP_BASE_URL
app.config.globalProperties.$axios = myAxios

app.use(router).use(ElementPlus).mount('#app')
