import { createRouter , createWebHashHistory } from "vue-router";

const layout = () => import('../components/layout/layout')
const index = () => import('../components/index/index')
const contactUs = () => import('../components/contactUs/contactUs')
const articleList = () => import('../components/article/list')
const articleDetail = () => import('../components/article/detail')
const doctorList = () => import('../components/doctor/list')
const doctorDetail = () => import('../components/doctor/detail')
const departmentList = () => import('../components/department/list')
const departmentDetail = () => import('../components/department/detail')
const aboutContent = () => import('../components/about/content')
const aboutMedical = () => import('../components/about/medical')
const aboutMedicalDetail = () => import('../components/about/medicalDetail')
const aboutLeader = () => import('../components/about/leader')
const aboutHonor = () => import('../components/about/honor')
const agreeInfo = () => import('../components/agree/detail')

const party_layout = () => import('../pages/party/components/layout/layout')
const party_index = () => import('../pages/party/view/index')
const partyList = () => import('../pages/party/view/list')
const partyDetail = () => import('../pages/party/view/detail')

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/layout',
        component: layout,
        children: [
            {
                path: '/index',
                component: index
            },
            {
                path: '/contactUs',
                component: contactUs
            },
            {
                path: '/news/:key',
                component: articleList
            },
            {
                path: '/news/:key/:id',
                name: 'newsDetail',
                component: articleDetail
            },
            {
                path: '/notice/:key',
                component: articleList
            },
            {
                path: '/notice/:key/:id',
                name: 'noticeDetail',
                component: articleDetail
            },
            {
                path: '/health/:key',
                component: articleList
            },
            {
                path: '/health/:key/:id',
                name: 'healthDetail',
                component: articleDetail
            },
            {
                path: '/nursing/:key',
                component: articleList
            },
            {
                path: '/nursing/:key/:id',
                name: 'nursingDetail',
                component: articleDetail
            },
            {
                path: '/doctor/:type',
                component: doctorList
            },
            {
                path: '/doctor/:type/:id',
                name: 'doctorDetail',
                component: doctorDetail
            },
            {
                path: '/department',
                component: departmentList
            },
            {
                path: '/department/:id',
                name: 'departmentDetail',
                component: departmentDetail
            },
            {
                path: '/about/content/:key',
                component: aboutContent
            },
            {
                path: '/about/medical',
                component: aboutMedical
            },
            {
                path: '/about/medical/:id',
                name: 'aboutMedicalDetail',
                component: aboutMedicalDetail
            },
            {
                path: '/about/leaders',
                component: aboutLeader
            },
            {
                path: '/about/honor',
                component: aboutHonor
            },
            {
                path: '/agree/:key',
                name: 'agreeInfo',
                component: agreeInfo
            },

        ]
    },
    {
        path: '/party_layout',
        component: party_layout,
        children: [
            {
                path: '/party',
                component: party_index
            },
            {
                path: '/party/:key',
                component: partyList
            },
            {
                path: '/party/:key/:id',
                name: 'partyDetail',
                component: partyDetail
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
export default router
