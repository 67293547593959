const CryptoJS = require('./aes.js'); //引用AES源码js
const key = "3917c4ccbcb2f24a1fb5a5e617ca71cf"; //32位秘钥


/**
 * aes 解密方法
 */
function AesDecrypt(encryptStr,hexAesKey) {
    let decrypted = CryptoJS.AES.decrypt(
        {ciphertext: CryptoJS.enc.Base64.parse(encryptStr)},
        CryptoJS.enc.Hex.parse(hexAesKey || key),
        {
            mode : CryptoJS.mode.ECB,
            padding : CryptoJS.pad.Pkcs7
        });
    return decrypted.toString(CryptoJS.enc.Utf8);
}


/**
 * aes 加密方法
 */
function AesEncrypt(word) {
    let encrypted = CryptoJS.AES.encrypt(word, CryptoJS.enc.Hex.parse(key), {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

/**
 * base64 加密方法
 */
function Base64Encode(val) {
    let str = CryptoJS.enc.Utf8.parse(val);
    let base64 = CryptoJS.enc.Base64.stringify(str);
    return base64;
}

/**
 * base64 解密方法
 */
function Base64Decode(val) {
    let words = CryptoJS.enc.Base64.parse(val);
    return words.toString(CryptoJS.enc.Utf8);
}


//暴露接口
module.exports = {
    AesEncrypt,
    AesDecrypt,
    Base64Encode,
    Base64Decode
}