import axios from "axios"
import router from "../router/index"
import {AesDecrypt} from "@/util/encrypt"
let baseURL= process.env.VUE_APP_BASE_URL

// 创建一个 axios 实例
const myAxios = axios.create({
    baseURL: baseURL,
    // timeout: 5000
})

// 从localStorage中获取token
/*function getLocalToken () {
    const token = window.localStorage.getItem('token')
    return token
}*/

// 给实例添加一个setToken方法，用于登录后将最新token动态添加到header，同时将token保存在localStorage中
myAxios.setToken = (token, _csrfToken) => {
    // myAxios.defaults.headers['X-Token'] = token
    window.localStorage.setItem('token', token)
    window.localStorage.setItem('csrfToken', _csrfToken)
    let hexAesKey = AesDecrypt(_csrfToken)?.replace(token, '')
    // hexAesKey = hexAesKey.replace(token,'')
    window.localStorage.setItem('hexAesKey',hexAesKey)
}

function refreshToken () {
    // myAxios是当前request.js中已创建的axios实例
    return myAxios.post('/llpfyy-web-api/api/token/refresh', {
        appId: "6347da2072dd54bec9f52f0a",
        appSecret: "jivDxkoL6B3zM54uXvMSo8jVtT5MOxff2xjTlmPN8KDuLZXjjF0h3Mrnnfkjf2xiKMnuugisFoVBG9l6KHTkiuisZTdtuPpl3eKXjqASoEDjk686ZBHJKw1uMz8TefnE",
    }).then(res => res.data)
}

// 设置请求拦截器
myAxios.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        if (config.method === "get") {
            config.data = true
        }
        config.headers["Content-type"] = "application/json";
        config.headers['llpfyyCsrfToken'] = window.localStorage.getItem('token')
        return config
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let requests = []

// 设置响应拦截器
myAxios.interceptors.response.use(
    function (response) {
        if((response.data.code === 401) || (response.data.message === 'token不能为空')) {
            const config = response.config
            if (!isRefreshing) {
                isRefreshing = true
                return refreshToken().then(res => {
                    const { token,  _csrfToken} = res.data
                    myAxios.setToken(token, _csrfToken)
                    // config.headers['X-Token'] = token
                    config.baseURL = baseURL
                    // 已经刷新了token，将所有队列中的请求进行重试
                    requests.forEach(cb => cb(token))
                    requests = []
                    return myAxios(config)
                }).catch(res => {
                    console.error('refreshtoken error =>', res)
                    // window.location.href = '/'
                }).finally(() => {
                    isRefreshing = false
                })
            } else {
                // 正在刷新token，将返回一个未执行resolve的promise
                return new Promise((resolve) => {
                    // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
                    requests.push((token) => {
                        config.baseURL = baseURL
                        // config.headers['X-Token'] = token
                        resolve(myAxios(config))
                    })
                })
            }
        }

        /*if (response.data.code === 0 && response.config.url.indexOf('token/refresh') === -1) {
            let responseData = response
            responseData.data.data = JSON.parse(AesDecrypt(responseData.data.data, window.localStorage.getItem('hexAesKey'))) || ''
            console.log(responseData)
            return responseData
        }*/
        return response
    },
    error => {
        // 错误处理
        return Promise.reject(error)
    }
)

export default myAxios